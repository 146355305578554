var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products category-med grey lighten-2"},[_c('category-block',{staticClass:"category-block category-block-1",attrs:{"target":_vm.category,"position":"header1"}}),(!_vm.$ebsn.meta(_vm.category, 'category_info.HEADER_IMAGE', true))?_c('CategoryTitle',{attrs:{"category":_vm.category,"count":_vm.count,"selectedCategory":_vm.selectedCategory}}):[_c('div',{staticClass:"category-title"},[_c('v-img',{staticClass:"rounded-0",attrs:{"src":_vm.$ebsn.meta(_vm.category, 'category_info.HEADER_IMAGE'),"cover":"","onerror":"this.onerror=null;this.src='/no-icon.png'","height":"170px"}},[_c('div',{staticClass:"layer"}),_c('ebsn-meta',{class:'pt-16 text-center main-category-title',attrs:{"target":_vm.category,"path":"category_info.TITLE","tag":"h1","defaultValue":_vm.category.name},on:{"click":_vm.clicked}}),_c('div',{staticClass:"category-description elevation-2"},[_c('ebsn-meta',{class:'SUBTITLE',attrs:{"target":_vm.category,"path":"category_info.SUBTITLE","tag":"h3"},on:{"click":_vm.clicked}}),_c('ebsn-meta',{class:'description',attrs:{"target":_vm.category,"path":"category_info.DESCRIPTION","tag":"p"},on:{"click":_vm.clicked}})],1)],1)],1)],_c('v-container',[_c('Breadcrumb',{attrs:{"items":_vm.category.navigationTypeId != 0 ? _vm.breadcrumbs : _vm.breadcrumb}}),(_vm.category.children)?_c('v-chip-group',{staticClass:"pb-16",attrs:{"show-arrows":""}},_vm._l((_vm.category.children),function(subCategory){return _c('v-chip',{key:subCategory.categoryId,staticClass:"mr-2 mb-2 secondary",attrs:{"label":"","link":"","exact-active-class":"secondary-darken1","to":_vm.category.navigationTypeId == 0
            ? { name: 'Category', params: { pathMatch: subCategory.slug } }
            : {
                name: 'Category',
                params: {
                  pathMatch: _vm.category.slug
                },
                query: { categoryId: subCategory.categoryId }
              }}},[_vm._v(" "+_vm._s(subCategory.name)+" ")])}),1):_vm._e(),_c('ProductListGrid',{key:_vm.categoryId || _vm.category.categoryId,attrs:{"parentCategoryId":_vm.category.categoryId,"categoryId":_vm.categoryId,"hideFilterString":"Categorie,Main Term","position":_vm.count != 0 ? 'category_med' : 'category_med_no_results'},on:{"productsCount":_vm.updateCount}})],1),_c('category-block',{staticClass:"category-block category-block-2",attrs:{"target":_vm.category,"position":"footer1"}}),_c('category-block',{staticClass:"category-block category-block-3",attrs:{"target":_vm.category,"position":"footer2"}}),_c('category-block',{staticClass:"category-block category-block-3",attrs:{"target":_vm.category,"position":"footer3"}}),_c('v-container',[_c('ebsn-meta',{attrs:{"target":_vm.category,"path":"category_info.FOOTER_DESCRIPTION","tag":"div"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }